<template>
  <v-row no-gutters class="mb-n15">
    <v-col cols="3" aling="center" justify="center">
      <v-card height="775" flat class="containerBorder mx-3">
        <v-card-title class="d-flex justify-center font-weight-bold primary--text">
          {{ $t('header') }}
        </v-card-title>
        <v-divider class="mx-3"></v-divider>
        <v-card class="overflow-y-auto" height="695" flat>
          <v-list text>
            <v-list-item-group>
              <v-list-item v-for="(item, i) in maps" :key="i" color="primary"
                @click="selectImage(item.mapImage, item.mapFloor, item.speech, item.mapName)">
                <v-list-item-content>
                  <v-card min-height="45" @click="selectedMap = item.mapName"
                    class="pa-0 ma-0 text-wrap white--text text-left"
                    v-bind:color="item.mapName === selectedMap ? '#15306B' : 'primary'">
                    <v-card-title class="text-subtitle-1">
                      {{
                        item.mapName.toUpperCase() | trimLength
                      }}
                    </v-card-title>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-card>
    </v-col>
    <v-col cols="9">
      <v-tabs active-class="tabClass" background-color="primary" centered height="70" dark v-model="active_tab">
        <v-tab v-for="(floor, index) in $t('floors')" :key="index"
          @click="selectImage(floor.floorImage.url, floor.floorNum, floor.speech, floor.floorName)" class="text-h6 pa-5">
          {{ floor.floorName }}
        </v-tab>
      </v-tabs>
      <v-row>
        <v-col align="center">
          <v-img :src="url" height="650" width="950">
          </v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'maps',
  data() {
    return {
      active_tab: 0,
      url: '',
      selectedMap: ''
    }
  },
  computed: {
    ...mapGetters(['getFloors', 'getMaps', 'getCMSlink']),
    maps() {
      let temp = []
      this.$i18n.t('maps').forEach(map => {
        temp.push(map)
      })
      return temp
    }
  },
  methods: {
    selectImage(mapPath, mapFloor, speech, mapName) {
      this.active_tab = mapFloor - 1;
      this.url = mapPath
      if (speech !== undefined) {
        this.$store.dispatch('avatarSpeak', speech)
        // Touch Usage
        this.$store.commit('setTouchRequest', {
          module: 'Maps and Directory',
          action: 'View Map',
          response: mapName,
          timeStamp: new Date(),
          requestType: 'Touch'
        })
      }
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 42) {
        return val
      }
      return `${val.substring(0, 40)}...`
    }
  },
  mounted() {
    this.selectImage(this.getFloors[0].floorImage.url, this.getFloors.floorNum)
  }

}
</script>
<style>
.tabClass {
  background-color: #15306B;
  color: white !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
</style>
<i18n>
    {
    "en": {
        "header": "MAP DIRECTORY"
      },
    "es":{
        "header": "Mapa directorio"
      }
  }
</i18n>