let request_as_promised = require('request-promise');
import router from "@/router";
import i18n from "../../i18n";

export default {
    state: {
        qnaStatus: null,
        qnaKeyword: '',
        wrongAnswerCount: 0,
        court: '',
        isThinking: false,
        requestQna: true
    },
    getters: {
        getQnaStatus: state => state.qnaStatus,
        getQnaKeyword: state => state.qnaKeyword,
        getWrongAnsCount: state => state.wrongAnswerCount,
        getCourt: state => state.court,
        getIsThinking: state => state.isThinking,
        getRequestQna: state => state.requestQna
    },
    mutations: {
        setQnaStatus(state, status) {
            state.qnaStatus = status;
        },
        setQnaKeyword(state, keyword) {
            state.qnaKeyword = keyword
        },
        setWrongAnswerCount(state, count) {
            state.wrongAnswerCount = count
        },
        setCourt(state, court) {
            state.court = court
        },
        setIsThinking(state, status) {
            state.isThinking = status
        },
        setRequestQna(state, status) {
            state.requestQna = status
        }
    },
    actions: {
        requestQnA({ getters, dispatch, commit }, transcript) {
            return new Promise((response, reject) => {
                if (getters.isThinking) {
                    commit("setSpeechTranscript", transcript);
                    commit("setTextBubbleText", transcript);
                }
                console.log('AT request QNA: ', transcript)
                let question = {
                    'question': transcript,
                    'top': 3,
                    'scoreThreshold': 10,
                    'rankerType': 'QuestionOnly'
                };
                let options = {
                    uri: getters.getAzureHost + getters.getAzureRoute,
                    method: 'POST',
                    headers: {
                        'Authorization': "EndpointKey " + getters.getAzureEndpointKey
                    },
                    json: true,
                    body: question
                };
                commit("setIsThinking", true)
                commit("setTextBubbleText", i18n.t("isThinking"))
                request_as_promised.post(options)
                    .then(result => {
                        const qnaAnalytics = {
                            timeStamp: new Date(),
                            kioskID: getters.getKioskId,
                            transcript: transcript,
                            result: result,
                        }
                        dispatch('addQnaDatatoStrapi', qnaAnalytics);
                        if (result.answers[0].score > 60) {
                            dispatch('generateAnswer', result)
                            commit('setTouchRequest', {
                                module: 'QnA',
                                action: transcript,
                                response: result.answers[0].answer,
                                timeStamp: new Date(),
                                requestType: 'Speech'
                            })
                        } else if (result.answers[0].score > 45) {
                            commit("setIsThinking", false)
                            if (getters.getCurrentLanguage === 'en-US') {
                                dispatch('avatarSpeak', 'I didn\'t quite get that, did you mean to ask following questions. Please tap your preference.')
                            } else {
                                dispatch('avatarSpeak', 'No entendía eso, querías hacer las siguientes preguntas. Por favor, toque su preferencia.')
                            }

                            let followUp = [];

                            commit('setTouchRequest', {
                                module: 'QnA',
                                action: transcript,
                                response: 'Follow Up question',
                                timeStamp: new Date(),
                                requestType: 'Speech'
                            })

                            result.answers.forEach(output => {
                                if (output.score > 45)
                                    followUp.push(output.questions[0])
                            });

                            dispatch('openOptionsViewer', {
                                dialogHeader: 'Please Tap the option you prefer',
                                dialogData: followUp,
                            })
                        } else {
                            console.log('Default Location: ', getters.getDefaultLocation)
                            commit("setIsThinking", false)
                            getters.getMaps.forEach(map => {
                                if (map.mapName.toLowerCase() === getters.getDefaultLocation.toLowerCase()) {
                                    dispatch('openImageViewer', {
                                        name: map.mapName,
                                        url: map.mapImage
                                    })
                                }
                            })
                            commit('setTouchRequest', {
                                module: 'QnA',
                                action: transcript,
                                response: 'Not Answered -  Add Action to KB',
                                timeStamp: new Date(),
                                requestType: 'Speech'
                            })
                            if (getters.getCurrentLanguage === 'en-US') {
                                dispatch('avatarSpeak', 'Sorry, I\'m not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the location circled in red.')
                            } else {
                                dispatch('avatarSpeak', 'Lo siento. No estoy seguro de cómo responder esta pregunta. Por favor, hazme una pregunta diferente, o puedes continuar usando la interfaz táctil. Además, puede obtener más información en la oficina del secretario ubicada aquí.')
                            }
                            //dispatch('avatarSpeak', 'Sorry, I\'m not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the Clerk’s Office located here.')
                        }

                    })
            })
        },
        generateAnswer({ dispatch, commit, getters }, result) {
            commit("setIsThinking", false)
            commit('setQnaStatus', false)
            const metadata = result.answers[0].metadata.find(({ name }) => name === 'intent').value
            const ansObj = result.answers[0]
            console.log('Form keyword : ', metadata)
            commit('setDefaultBubbleText', false)
            commit('closeOptionsViewer')
            switch (metadata) {
                case "navigate":
                    var menuName = ansObj.metadata.find(({ name }) => name === 'navigateto').value;
                    router.addRoute({ path: menuName.toLowerCase(), component: () => import('@/views/' + menuName) });
                    if (router.currentRoute.path !== menuName) {
                        router.push(menuName).then(() => {
                            commit('setQnaKeyword', '')
                            dispatch('avatarSpeak', ansObj.answer);
                            let page = i18n.t('homepages').filter(page => page.pageType[0].pathName === menuName)
                            commit('setPageHeader', page[0].pageType[0].pageHeader)
                        })
                    } else {
                        commit('setQnaKeyword', '')
                        dispatch('avatarSpeak', ansObj.answer)
                    }
                    break;

                case "showmap":

                    try {
                        var mapPath
                        let mapName = ansObj.metadata.find(({ name }) => name === 'mappath').value
                        getters.getMaps.forEach(map => {
                            if (map.mapName.toLowerCase() === mapName) {
                                mapPath = map
                            }
                        })
                        dispatch('openImageViewer', {
                            name: mapPath.mapName,
                            url: mapPath.mapImage
                        })
                        if (getters.getCurrentLanguage === "en-US") {
                            dispatch('avatarSpeak', ansObj.answer);
                        }

                    } catch (e) {
                        console.log(e.message)
                        getters.getMaps.forEach(map => {
                            if (map.mapName.toLowerCase() === getters.getDefaultLocation.toLowerCase()) {
                                dispatch('openImageViewer', {
                                    name: map.mapName,
                                    url: map.mapImage
                                })
                            }
                        })

                        //dispatch('avatarSpeak', 'Sorry, I\'m not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the Clerk’s Office located here.')
                        if (getters.getCurrentLanguage === 'en-US') {
                            dispatch('avatarSpeak', 'Sorry, I\'m not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the location circled in red.')
                        } else {
                            dispatch('avatarSpeak', 'Lo siento. No estoy seguro de cómo responder esta pregunta. Por favor, hazme una pregunta diferente, o puedes continuar usando la interfaz táctil. Además, puede obtener más información en la oficina del secretario ubicada aquí.')
                        }
                    }
                    break;

                case "speak":

                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showform":
                    try {
                        var formName = ansObj.metadata.find(({ name }) => name === 'formname').value
                        i18n.t('forms').forEach(form => {
                            if (form.formName.toLowerCase() === formName) {
                                dispatch('openPdfViewer', {
                                    url: form.formPdf,
                                    name: form.formName,
                                    emailLink: form.pdfUrl,
                                    type: 'Form'
                                })
                                dispatch('avatarSpeak', ansObj.answer);
                            }
                        })
                    } catch (e) {
                        console.log(e.message)
                        getters.getMaps.forEach(map => {
                            if (map.mapName.toLowerCase() === getters.getDefaultLocation.toLowerCase()) {
                                dispatch('openImageViewer', {
                                    name: map.mapName,
                                    url: map.mapImage
                                })
                            }
                        })
                        //dispatch('avatarSpeak', 'Sorry! I just started working here, I will soon know answers to all the court related questions. Pleas try asking different question or visit Clerk\'s office.');
                        if (getters.getCurrentLanguage === 'en-US') {
                            dispatch('avatarSpeak', 'Sorry, I\'m not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the location circled in red.')
                        } else {
                            dispatch('avatarSpeak', '¡Lo siento! Acabo de empezar a trabajar aquí, pronto sabré respuestas a todas las preguntas relacionadas con la corte. Por favor, intente hacer otra pregunta o visitar la oficina de Clerk.')
                        }
                    }
                    break;

                case "showfaqcategory":
                    commit("setQnaKeyword", "")
                    var faqKeyword = ansObj.metadata.find(({ name }) => name === 'keyword').value

                    router.addRoute({ path: '/faqs', component: () => import('@/views/faqs') })
                    if (router.currentRoute.path !== '/faqs') {
                        router.push('/faqs')
                    }
                    commit('setPageHeader', 'Frequently Asked Questions')
                    var count = i18n.t('faqCategory').filter(cat => cat.categoryName.toLowerCase() === faqKeyword)
                    if (count.length === 0) {
                        //dispatch('avatarSpeak', 'not found');
                        commit("setQnaKeyword", faqKeyword)
                    } else {
                        commit('setSelectedFaqCat', { name: faqKeyword, type: 'expansionPanel' })
                    }
                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showformcategory":

                    var formKeyword = ansObj.metadata.find(({ name }) => name === 'keyword').value
                    console.log('Form keyword : ', formKeyword)
                    router.addRoute({ path: '/forms', component: () => import('@/views/forms') })
                    if (router.currentRoute.path !== '/forms') {
                        router.push('/forms')
                    }
                    commit('setPageHeader', 'Forms')
                    commit('setSelectedFormCat', { category: formKeyword, showTab: true })
                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showformpackets":
                    var packetInfo;
                    packetInfo = getters.getFormPackets.filter(pack => pack.packetName.toLowerCase() === ansObj.metadata.find(({ name }) => name === 'keyword').value)
                    dispatch('openPdfViewer', {
                        url: packetInfo[0].packetFiles,
                        name: packetInfo[0].packetName,
                        desc: packetInfo[0].packetDesc,
                        emailLink: packetInfo[0].packetLink,
                        type: 'Packet'
                    })
                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showimage":
                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showhours":
                    //dispatch('openCourtTimingViewer', {})
                    dispatch('avatarSpeak', ansObj.answer);

                    break;

                case "session":
                    var action = ansObj.metadata.find(({ name }) => name === 'action').value
                    if (action === "still there") {
                        dispatch('kioskStillThere')
                    } else {
                        dispatch('resetKiosk')
                    }
                    break;

                case "followup":

                    var prompts = (ansObj.context).prompts
                    var followUp = [];
                    dispatch('avatarSpeak', ansObj.answer);
                    prompts.forEach(item => followUp.push(item.displayText));
                    dispatch('openOptionsViewer', {
                        dialogHeader: 'Tap on the below, or click on the microphone to ask another question',
                        dialogData: followUp,
                    })
                    break;
                default:
                    getters.getMaps.forEach(map => {
                        if (map.mapName.toLowerCase() === getters.getDefaultLocation.toLowerCase()) {
                            dispatch('openImageViewer', {
                                name: map.mapName,
                                url: map.mapImage
                            })
                        }
                    })
                    //dispatch('avatarSpeak', 'Sorry I’m still learning. Please ask me a different question or, get information about this topic at the Clerk’s Office located here.')
                    if (getters.getCurrentLanguage === 'en-US') {
                        dispatch('avatarSpeak', 'Sorry, I\'m not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the location circled in red.')
                    } else {
                        dispatch('avatarSpeak', '¡Lo siento! Acabo de empezar a trabajar aquí, pronto sabré respuestas a todas las preguntas relacionadas con la corte. Por favor, intente hacer otra pregunta o visitar la oficina de Clerk.')
                    }
                    break;
            }
            // ToDo - Have to complete the intent based answers.
        }

    }
}
