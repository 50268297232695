import axios from 'axios'

const fs = require('fs')

export default {
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        addQnaDatatoStrapi({ getters }, qnaData) {
            console.log('QNA Data: ', qnaData)
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://cms.d8taos.arsconnect.com/qnas',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: qnaData
            };
            axios.request(config)
                .then((response) => {
                    //console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        addSessionDatatoStrapi({ getters }, sessionData) {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://cms.d8taos.arsconnect.com/user-sessions',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: sessionData
            };
            axios.request(config)
                .then((response) => {
                    //console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    //console.log(error);
                });
        },
        addSpeakDatatoStrapi({ getters }, speechData) {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://cms.mdcourts.arsconnect.com/speech-managers',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: speechData
            };
            axios.request(config)
                .then((response) => {
                    //console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    //console.log(error);
                });
        }
    }
}
