import router from "@/router";
import i18n from "../../i18n";
export default {
    state:{
        showRecognitionPage: 'none',
        enableAllRecognition: false,
        enableSpeechRecognition: true,
        enableFaceDetection: true,
        speechRecognitionStatus: false,
        faceDetectionStatus: false,
        isUserApproached: false,
        isUserLooking: false,
        faceCount: 0,
        isAvatarListening: false,
        speechTranscript: '',
        speechRecognitionHandler: false,
        greetingStatus: false,
        resetKioskStatus: false,
        timeoutID: 0,
        completeUserSession: 0,
        touchCount: 0

    },
    getters:{
        enableAllRecognition: state => state.enableAllRecognition,
        enableSpeechRecognition: state => state.enableSpeechRecognition,
        enableFaceDetection: state => state.enableFaceDetection,
        speechRecognitionStatus: state => state.speechRecognitionStatus,
        faceDetectionStatus: state => state.faceDetectionStatus,
        isAvatarListening: state => state.isAvatarListening,
        faceCount: state => state.faceCount,
        speechTranscript: state => state.speechTranscript,
        isUserLooking: state => state.isUserLooking,
        isUserApproached: state => state.isUserApproached,
        showRecognitionPage: state => state.showRecognitionPage,
        speechRecognitionHandler: state => state.speechRecognitionHandler,
        getGreetingStatus: state => state.greetingStatus,
        getResetKioskStatus: state => state.resetKioskStatus,
        getTimeoutId: state => state.timeoutID,
        getTouchCount: state => state.touchCount
    },
    mutations:{
        setEnableAllRecognition(state, status){
            state.enableAllRecognition = status;
        },
        setEnableSpeechRecognition(state, status){
            state.enablespeechRecognition = status;
        },
        setEnableFaceDetection(state, status){
            state.enablefaceDetection = status;
        },
        setSpeechRecognitionStatus(state, status){
            state.speechRecognitionStatus = status
        },
        setFaceDetectionStatus(state, status){
            state.faceDetectionStatus = status
        },
        setIsAvatarListening(state, status){
            state.isAvatarListening = status;
        },
        setFaceCount(state, count){
            state.faceCount = count;
        },
        setSpeechTranscript(state, transcript){
            state.speechTranscript = transcript;
        },
        setIsUserLooking(state, status){
            state.isUserLooking = status
        },
        setIsUserApproached(state, status){
            state.isUserApproached = status
        },
        setShowRecoginitionPage(state, status){
            state.showRecognitionPage = status
        },
        setSpeechRecognitionHandler(state, status){
            state.speechRecognitionHandler = status;
        },
        setGreetingStatus(state, status){
            state.greetingStatus = status
        },
        setResetKioskStatus(state, status){
            state.resetKioskStatus = status
        },
        setTimeoutID(state, value){
            state.timeoutID = value
        },
        resetTimeoutID(state){
            clearTimeout(state.timeoutID)
        },
        setTouchCount(state, count) {
            state.touchCount = count
        }
    },
    actions:{
        resetKiosk({dispatch, commit, state}){
            clearTimeout(state.timeoutId)
            commit('setResetKioskStatus', true)
            state.timeoutId = setTimeout(function (){
                commit('setDefaultBubbleText', true)
                commit('setSessionEndViewer', true)
                // state.completeUserSession = setTimeout(function(){
                //     dispatch('kioskStartOver')
                // }, 15000)
            }, 15000)
        },
        kioskStartOver({commit, state, dispatch}){
            if(router.currentRoute.path !== '/'){
                router.push('/').then(() => {
                    console.log('All good')
                })
            }
            i18n.locale = 'en'
            commit('setCurrentLanguage', 'en-US');
            dispatch("setDefaults", "en")
            commit('setTouchCount', 0)
            commit('setResetKioskStatus', false)
            commit('setGreetingStatus', false)
            commit('closeOptionsViewer')
            commit('closeEmailViewer')
            commit('closePdfViewer')
            commit('setSessionEndViewer', false)
            commit('setTextBubbleText', 'Hi, my name is Clara, I’m here to help you. Tap the microphone button, and ask me ‘What can you do?’')
            commit('resetTimeoutID')
            clearTimeout(state.timeoutId)
            clearTimeout(state.completeUserSession)
            commit('setTouchRequest', {
                module: 'Default',
                action: 'User Left',
                response: 'User Session Ended',
                timeStamp: new Date(),
                requestType: 'Session'
            })
            commit('setEndUserSession', new Date())
            dispatch('updateUserSession')
        },
        kioskStillThere({commit, state}){
            clearTimeout(state.timeoutId)
            clearTimeout(state.completeUserSession)
            commit('resetTimeoutID')
            commit('closeOptionsViewer')
            commit('setResetKioskStatus', false)
            commit('setSessionEndViewer', false)
            commit('setTextBubbleText', 'Tap on the microphone button, to ask me a question.')
        },
        clearResetTimeout({state}) {
            clearTimeout(state.timeoutId)
            clearTimeout(state.completeUserSession)
        }
    }
  }
