import Vue from 'vue'
import VueRouter from 'vue-router'
import {auth} from "@/firebase";
import Home from '../views/Home.vue'
import login from '@/views/login.vue'
import faqs from '@/views/faqs.vue'
import maps from '@/views/maps.vue'
import forms from '@/views/forms.vue'
import loadData from '@/views/loadData.vue'
import recognition from '@/components/recognition.vue';
// import todayshearing from "@/views/todayshearing";
import todayshearing from "@/views/hearingList.vue";
import jurycheckin from "@/views/juryCheckin.vue"
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/loadData',
    name: 'loadData',
    component: loadData,
    meta:{
      requiresAuth: true,
    },
  },
  {
    path: '/rec',
    name: 'recognition',
    component: recognition,
    meta:{
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: faqs,
    meta:{
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
  {
    path: '/maps',
    name: 'maps',
    component: maps,
    meta:{
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
  {
    path: '/forms',
    name: 'forms',
    component: forms,
    meta:{
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
  {
    path: '/todayshearing',
    name: 'todayshearing',
    component: todayshearing,
    meta:{
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
  {
    path: '/juryCheckin',
    name: 'juryCheckin',
    component: jurycheckin,
    meta:{
      requiresAuth: true,
      requireDataLoaded: true,
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const requireDataLoaded = to.matched.some(x => x.meta.requireDataLoaded);
  if(requiresAuth && !auth.currentUser){
    next('/login');
  }
  else if(requireDataLoaded && !store.getters.getDataLoaded){
    next('/loadData');
  }
  else{
    next();
  }
})

export default router
