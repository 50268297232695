import gql from 'graphql-tag'

//---------------------------- HomePage Queries ---------------------------------

export const homepageQuery = gql`
query homeQuery($alias : JSON, $lang: String, $location: String) {
  homepages (sort: "displayPosition:asc", where : { alias : $alias, kioskLocationCode_contains : $location }, locale: $lang) {
    id
    speech    
    menuName
    locale 
    alias    
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename
        textList      
      }
    }
    menuIcon{
      id
      url
    }
  }
}
`

//---------------------------- Faq Page Queries ---------------------------------

export const faqCategoryQuery = gql`
query faqCategoryQuery($lang: String, $location: String) {
  faqCategories(sort: "displayOrder", locale: $lang, where : { courtLocationCode_contains : $location}) {
    id
    categoryName
    displayType
    displayOrder
    locale
  }
}
`

export const faqQuery = gql`
query faqQuery($lang: String, $location: String) {
  faqs (sort: "id", locale: $lang, where : { courtLocationCode_contains : $location}) {
    id
    category
    locale
    faqType{
      ... on ComponentFaqDisplayExpansionPanel{
        question
        answer
      }
      ... on ComponentFaqDisplayButton{
        guideName
        guideLink
        guide {
          id
          url
        }
      }
    }
  }
}
`
//---------------------------- Map Page Queries ---------------------------------

export const floorQuery = gql`
query floorQuery($lang: String, $location: String) {
  floors (sort: "floorNumber:asc", locale: $lang, where : { kioskLocationCode: $location }){ 
    id
    floorName
    floorNumber
    speech
    locale
    floorImage {
      id
      url
    }
  }
}
`

export const mapQuery = gql`
query mapQuery($lang: String,  $location: String) {
  maps(sort: "displayPosition:asc", locale: $lang, where : { kioskLocationCode: $location }){
    id
    mapName
    mapFloor
    speech
    locale
    mapImage {
      id
      url
    }
  }
}
`

//---------------------------- Form Page Queries ---------------------------------


export const formCategoryQuery = gql`
query formCategoryQuery ($lang: String, $location: String) {
  formCategories(sort: "id", locale: $lang, where : { courtLocationCode_contains : $location}) {
    id
    categoryName
    displayType
    categoryType
    locale
  }
}
`

export const allFormQuery = gql`
query allFormQuery ($lang: String, $location: String) {
  allForms(start: 0, sort: "id", locale: $lang, where : { courtLocationCode_contains : $location}) {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    courtType
    locale
    formNumber
    formPdf{
      id
      url
    }
  }
}
`

export const allFormSecondQuery = gql`
query allFormQuery($lang: String, $location: String) {
  allForms(start: 100, sort: "id", locale: $lang, where : { courtLocationCode_contains : $location}) {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    courtType
    locale
    formNumber
    formPdf{
      id
      url
    }
  }
}
`

export const allFormThirdQuery = gql`
query allFormQuery($lang: String, $location: String) {
  allForms(start: 200, sort: "id", locale: $lang, where : { courtLocationCode_contains : $location}) {
    id
    formName
    displayIcon
    formCategory
    pdfUrl
    courtType
    locale
    formNumber
    formPdf{
      id
      url
    }
  }
}
`


export const formPacketQuery = gql`
query formPacketQuery($location: String) {
    formPackets( where : { courtLocationCode_contains : $location}) {
        id
        packetName
        packetDesc
        packetLink
        locale
        packetFiles{
            id
            url
        }
    }
}
`
