<template>
    <v-row align="center">
        <v-col cols="9" justify="center" align="center" align-self="center">
            <iframe width="95%" height="700" src="https://jurorportal.nmcourts.gov/qualification/"
                sandbox="allow-scripts allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation"
                class="iframe" id="iframeId" title="New Mexico Juror Portal">
            </iframe>
        </v-col>
        <v-col cols="3" justify="center" align="center">
            <v-btn width="250" color="primary"> Open keyboard</v-btn>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'juryCheckin',
    methods: {

    },
    mounted() {
        const openOnScreenKeyboard = () => {
            const oskProcess = require('child_process').exec;
            oskProcess('osk');
        };

        // Check if the current page URL is the specific page where you want to open the OSK
        if (window.location.href === 'http://localhost:8081/jurycheckin') {
            openOnScreenKeyboard();
        }
    }
}
</script>
